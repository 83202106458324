<template>
  <div class="headerPage">
    <van-nav-bar :title="params.title" left-arrow :border="false">
      <template #left>
        <div class="left-div" @click="goBack">
          <van-icon name="arrow-left" size="18"  v-if="params.leftIcon" />
          <i v-else></i>
          <span class="left-text" v-if="params.leftIcon">返回</span>
        </div>
      </template>
      <template #right>
        <van-icon name="delete-o" color="#000" size="20" v-if="params.rightIcon" />
        <span @click="goPage(page)">{{params.right}}</span>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
//
export default {
  name: "navBar",
  props: ["params"],
  data(){
    return{
      page:'feedbackHistory'
    }
  },
  methods: {
    goBack(){
      // console.log(this.$router.options.routes);
      // this.$router.options.routes.length =this.$router.options.routes.length - 1

      //登录界面返回上一页,如果有url则返回到首页
      if (this.$route.params.return_url != undefined || (this.$store.state.common.loginInfo.access_token != null && this.$store.state.common.loginInfo.access_token != ''))
      {
        if (this.$route.params.return_url === 'attendance'){
          this.$router.push({name:'home'});
          return;
        }
        if (window.location.hash.toString().includes("forecast"))
        {
          this.$router.push({name:'home'});
          return;
        }
        else if(this.$route.params.return_url != undefined && (this.$store.state.common.loginInfo.access_token == null || this.$store.state.common.loginInfo.access_token == '')){
          this.$router.push({name:'forecast',params:{return_url:this.$route.params.return_url}});
          return;
        }
        else if ((this.$store.state.common.loginInfo.access_token != null && this.$store.state.common.loginInfo.access_token != '') && window.location.hash.toString().includes("contentDetail"))
        {
          //这一步处理的是登录后跳转到文章页面时 返回的时候不会再回到登录页面
          this.$router.push({name:'forecast'});
          return;
        }
        else
        {
          this.$router.back();
          return;
        }
      }else {
        this.$router.back();
      }
    }
  },
  created(){
    let real = this.$props.params.real;
    let defriend = this.$props.params.defriend;
    let feedback = this.$props.params.feedback;
    if(defriend){
      this.page = 'defrienddesc'
    }
    else if(feedback){
      this.page = 'feedbackHistory'
    }
    else if(real){
      this.page = 'identify'
    }else{
      this.page = 'desc'
    }
  }
};
</script>

<style lang="less">
.headerPage {
  // .van-nav-bar {
  //   background-color: #0252ff;
  //   z-index: 10000;
  //   .van-nav-bar__left {
  //     .van-icon {
  //       color: white;
  //     }
  //   }
  //   .van-nav-bar__left {
  //     opacity: 0.85;
  //   }
  //   .van-nav-bar__title {
  //     color: rgba(255, 255, 255, 0.9);
  //     font-size: 17px;
  //     font-weight: normal;
  //   }
  // }
  // .ask_img .van-icon__image {
  //   border-radius: 50%;
  // }
  .left-div{
    display: flex;
    align-items: center; 
  }
}
</style>
