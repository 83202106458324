<template>
  <div >
    <img  v-if="showImg" style="width:100%;" src="../../assets/images/iswechat.jpeg"/>

    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: false}"></v-header>
    </div>
    <div>
      <van-notice-bar
        color="#1989fa"
        background="#ecf9ff"
        left-icon="volume-o"
        text="充值规则：1元等于1个月亮"
      />

      <div class="list">
        <div v-for="(item,index) in money" :key="index">
          <van-badge v-if="item.isHot" content="Hot" >
            <button @click="topay(item.originalPrice,item.rcId)">
              <p>{{item.originalPrice}}元</p>
              <p v-show="item.starPoint !== null && item.starPoint !=='' && item.starPoint !== '0' && item.starPoint !== 0" class="left">赠送{{item.starPoint}}星星</p>
            </button>
          </van-badge>
          <van-badge v-else content="" >
            <button @click="topay(item.originalPrice,item.rcId)">
              <p>{{item.originalPrice}}元</p>
              <p v-show="item.starPoint !== null && item.starPoint !=='' && item.starPoint !== '0' && item.starPoint !== 0" class="left">赠送{{item.starPoint}}星星</p>
            </button>
          </van-badge>
          
        </div>
        <!-- 隐藏自定义 -->
        <div v-show="false">
          <van-popover v-model="showPopover" bottom>
              <div class="item">
                <van-field class="input" v-model="num" type="digit" label="充值金额" placeholder="请输入10-1000的10的整数"  />
                <button class="pay" @click="diypay(num)">立即充值</button>
              </div>
                
              <template #reference>
                <button style="font-size: 0.3rem;margin:0.1rem;" @click="(showPopover=!showPopover)">
                  <p>自定义金额</p>
                  <p class="left" style="color: #1414cebd;font-size: 0.2rem;">根据金额赠送星星</p>
                </button>
              </template>
          </van-popover>
        </div>
        <van-checkbox style="margin-left:20px;" v-model="checked" shape="square"><p>我已阅读并同意<span style="color:blue" @click="goPage('appreciate')">《充值服务协议》</span></p></van-checkbox>
      </div>
      <p style=" display: inline-block; margin: 10px 20px; color: #ff0000;">充值完成后，可以返回上一页看余额，如有任何疑问，请联系客服微信：<button style="border:none;" @click="copy">haojingchou6</button></p>
      
    </div>
  </div>
 
  
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from "@/utils/utils.js";

export default {
  data(){
    return{
      checked:true,
      showImg:false,
      title:'充值页面',
      showPopover:false,
      num:'',
      money:[
        {originalPrice:'10',isHot:false,starPoint:'100'},
        {originalPrice:'20',isHot:false,starPoint:'200'},
        {originalPrice:'30',isHot:false,starPoint:'300'},
        {originalPrice:'50',isHot:true,starPoint:'500'},
        {originalPrice:'100',isHot:true,starPoint:'1000'},
        {originalPrice:'200',isHot:false,starPoint:'2000'},
        {originalPrice:'300',isHot:false,starPoint:'3000'},
      ],
      // userId:this.$store.state.common.loginInfo.userId,
      userId:this.$route.query.userid,
      token:this.$route.query.access_token,
      // rcid:this.$route.query.rc_id,
    }
  },
  components:{
        vHeader
  },
  methods:{
    async topay(n,rcId){
      console.log(n,rcId)
      if(this.checked){
        let args = {
            appId:"wxfeb0f4d0858263a3",
            artId: "101",
            bodyContent: "付费阅读",
            openid: "",
            payId: 3,
            buyType: 3,
            price: n,
            titles: "付费阅读",
            transactionType: "H5",
            // money: n,
            userId: this.userId,
            extendJson: "{userId:"+this.userId+",rcId:"+rcId+"}",
          }
      
        const res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
        // if (res.statusCode === 200) {
        //     this.$toast('充值成功');
        //
        // }
        if(res && res.statusCode === 200){
          this.$toast.clear()
          let newUrl = res.prepayUrl;
          window.location.href = newUrl;
        } else {
          console.log(res.responseDescription);
        }
      }else{
        this.$toast('好劲抽提醒您：请阅读并同意《充值服务协议》')
      }
      
    },

    async diypay(n){
      console.log(n)
      if(n!==''){
        if(n >= 10 && n <= 1000 && n%10 === 0){
          this.showPopover=false
         
          let args = {
            appId:"wxfeb0f4d0858263a3",
            artId: "101",
            bodyContent: "付费阅读",
            openid: "",
            payId: 3,
            buyType: 3,
            price: n,
            titles: "付费阅读",
            transactionType: "H5",
            // money: n,
            userId: this.userId,
            extendJson: "{userId:"+this.userId+",rcId:"+this.rcid+"}",
          }
          const res = await this.$HTTP.post(this, 'API_PAYMENT_PRODUCT', args, { headers: { access_token: this.token } }, true)
          // if (res.statusCode === 200) {
          //     this.$toast('充值成功');
          // }
          if(res && res.statusCode === 200) {
            this.$toast.clear()
            let newUrl = res.prepayUrl;
            window.location.href = newUrl;
          } else {
            console.log(res.responseDescription);
          }
        } else {
          this.$toast('请输入10的倍数后点击立即充值');
        }
      }else{
        this.$toast('请输入10的倍数后点击立即充值');
      }
      
    },
    copy(){
      this.$toast('已复制到剪切板')
      this.$copyText('haojingchou6').then(function () {
          // alert('已复制到剪切板')
        }, function () {
          this.$toast('复制失败！')
          
        })
    },
    async getmoney(){
      const res = await this.$HTTP.get(this, this.$API['API_RECHARGE_CONFIG'],{}, {}, true)
                                if(res.statusCode == 200){
                                  //  console.log('后台返回的配置充值金额',res)
                                   res.data.forEach((item)=>{
                                    if(item.starPoint!=null)
                                    item.starPoint=parseInt(item.starPoint)
                                   })
                                   this.money=res.data
                                }
    }
  },
  mounted(){
    let isWeiXin = isWeixin();
        if (isWeiXin) {
            this.showImg=true
        }
        
    //需要登录后才可以进入该界面
    
      if (
        (this.$store.state.common.loginInfo.access_token===null || this.$store.state.common.loginInfo.access_token === '' || this.$store.state.common.loginInfo.access_token === undefined)&&
        (this.token === null || this.token ==='' || this.token === undefined)
      ) {
        this.$router.replace('/login')
      }
    
    
    // console.log(this.$router)
    this.getmoney()
    // console.log('通过url传的值userid：',this.$route.query.userid)
    // console.log('rc_id：',this.$route.query.rc_id)
  }


}
</script>

<style lang="less" scoped>
*{
  margin: 0;
  padding: 0;
}
p{
  font-size: 0.35rem;
}
.item{
  width: 8rem;
  height: 1.5rem;
  .van-field__control{display: inline-block;}
  .pay{
    margin: 0 3rem;
    display: inline-block;
    width: 1.5rem;
    height: 0.5rem;
    font-size: 0.3rem;
    color:white;
    background-color:gray;
  }
}

.list{

  div{
    margin:0.1rem;
    // display: inline-block;
    float: left;
    button{
      width: 2.8rem;
      height: 2rem;
      border-radius: 8%;
      border: 1px solid blue;
      color: blue;
      background-color: white;
     
      p{
        font-size: 0.3rem;
      }
      .left{
        position:relative;
        top: 0.3rem;
        text-align: right;
        font-size: 0.2rem;
        color: #1414cebd;
      }
    }
  }
  
    
  
}
.child {
    width: 40px;
    height: 40px;
    background: #f2f3f5;
    border-radius: 4px;
  }
</style>